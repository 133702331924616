import React, { Component } from 'react'
import Layout from '../layout/layout'
import Helmet from 'react-helmet'
import ScrollManager from '../helper/scrollManager'
import posed from 'react-pose'
import { easeFunction } from '../helper/variables'
import { connect } from 'react-redux'
import HeadBlock from '../components/serviceDetailBlocks/headBlock'
import Circle from '../components/graphic/circle'
import ContentBlock from '../components/serviceDetailBlocks/contentBlock'
import Footer from '../components/common/footer'
import WorksBlock from '../components/serviceDetailBlocks/worksBlock'
import _ from 'lodash'

import './service.css'
import { graphql } from 'gatsby'
import { isBrowser, BrowserView } from 'react-device-detect'
import SectionIndicator from '../components/common/sectionIndicator'
import VisibilityWrapper from '../helper/visibilityWrapper'
import CTA from '../components/common/cta'
import ReactGA from 'react-ga'

const PageTransition = posed.div({
  enter: {
    opacity: 1,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
})

class Service extends Component {
  state = {
    mainNode: null,
    triggerPointCTA: 0,
    triggerPointFooter: 0,
    scrollValue: 0,
    momentumScrollValue: 0,
    randomPosts: null,
  }

  constructor(props) {
    super(props)

    this.mainNode = React.createRef()
    this.sectionCTA = React.createRef()
    this.sectionFooter = React.createRef()
  }

  componentDidMount() {
ReactGA.initialize('UA-48349543-1')

    if (typeof window !== 'undefined')
      ReactGA.pageview(window.location.pathname + window.location.search)

    this.pickRandom()

    this.setState({
      mainNode: this.mainNode.current,
    })

    this.assignTriggerPoints()

    if (typeof window !== 'undefined')
      window.addEventListener('resize', this.assignTriggerPoints)

    this.props.hideSymbol()
    this.props.backgroundWhite()
    this.props.noHomepageLoading()
  }

  assignTriggerPoints = event => {
    const { momentumScrollValue } = this.state

    this.setState({
      triggerPointCTA:
        momentumScrollValue +
        this.sectionCTA.current.getBoundingClientRect().top,
      triggerPointFooter:
        momentumScrollValue +
        this.sectionFooter.current.getBoundingClientRect().top,
    })
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined')
      window.removeEventListener('resize', this.assignTriggerPoints)

    this.props.backgroundWhite()
  }

  updateMomentumScrollValue = value => {
    this.setState({
      momentumScrollValue: value,
    })

    if (value > this.state.triggerPointFooter - (isBrowser ? 75 : 55)) {
      this.props.backgroundBlack()
    } else if (value > this.state.triggerPointCTA - (isBrowser ? 75 : 55)) {
      this.props.backgroundRed()
    } else {
      this.props.backgroundWhite()
    }
  }

  updateScrollValue = value => {
    this.setState({
      scrollValue: value,
    })
  }

  pickRandom = () => {
    this.setState(
      {
        randomPosts: _.take(
          _.shuffle(this.props.data.allContentfulWork.edges),
          2
        ),
      },
      () => {
        this.assignTriggerPoints()
      }
    )
  }

  render() {
    const page = this.props.pageContext.data
    const { theme } = this.props
    const { momentumScrollValue, randomPosts } = this.state

    return (
      <Layout ref={this.mainNode}>
        <Helmet>
          <html lang="en" />
          <title>{page.metaData.metaTitle}</title>
          <meta name="description" content={page.metaData.description} />
          <meta name="keywords" content={page.metaData.keywords} />
          <meta property="og:title" content={page.metaData.metaTitle} />
          <meta property="og:description" content={page.metaData.description} />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={
              page.metaData.ogImage
                ? `https:${page.metaData.ogImage.file.url}`
                : 'https://www.baunfire.com/ogimage.jpg'
            }
          />
          <meta
            property="og:url"
            content={`https://www.baunfire.com/${page.slug}`}
          />
          <link
            rel="canonical"
            href={`https://www.baunfire.com/${page.slug}`}
          />
        </Helmet>
        <PageTransition>
          <ScrollManager
            page={this.state.mainNode}
            scrollValue={this.state.scrollValue}
            updateScrollValue={this.updateScrollValue}
            updateMomentumScrollValue={this.updateMomentumScrollValue}
          >
            <section className="page-service-detail">
              <Circle theme={theme} />
              <section className="service-detail-head">
                <HeadBlock
                  page={page}
                  momentumScrollValue={momentumScrollValue}
                />
              </section>
              <section className="content-container service-detail-content">
                <ContentBlock
                  page={page}
                  momentumScrollValue={momentumScrollValue}
                />
              </section>
            </section>
            <section className=" service-detail-works">
              {randomPosts && (
                <WorksBlock
                  posts={randomPosts}
                  momentumScrollValue={momentumScrollValue}
                />
              )}
            </section>
            <VisibilityWrapper partialVisibility={true}>
              {({ isVisible }) => {
                return (
                  <div
                    className={`area-cta ${isVisible && 'appear'}`}
                    ref={this.sectionCTA}
                  >
                    <div className="content-container">
                      <section className="section-cta">
                        <CTA momentumScrollValue={momentumScrollValue} />
                      </section>
                    </div>
                  </div>
                )
              }}
            </VisibilityWrapper>
            <section ref={this.sectionFooter}>
              <Footer />
            </section>
          </ScrollManager>
          <BrowserView>
            <SectionIndicator theme={theme}>What we do</SectionIndicator>
          </BrowserView>
        </PageTransition>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allContentfulWork(limit: 1000, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          slug
          project
          type
          thumbnail {
            file {
              url
              fileName
              contentType
            }
            description
            fluid(maxWidth: 840, quality: 90) {
              src
              srcSet
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const mapStateToProps = state => {
  return {
    theme: state.backgroundColor,
    loaded: state.loaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    backgroundBlack: () => dispatch({ type: 'BACKGROUND_BLACK' }),
    backgroundWhite: () => dispatch({ type: 'BACKGROUND_WHITE' }),
    backgroundRed: () => dispatch({ type: 'BACKGROUND_RED' }),
    noHomepageLoading: () => dispatch({ type: 'NO_HOMEPAGE_LOADING' }),
    hideSymbol: () => dispatch({ type: 'SHOW_LOGO' }),
    flagLoad: () => dispatch({ type: 'LOADED' }),
  }
}

const ConnectedService = connect(
  mapStateToProps,
  mapDispatchToProps
)(Service)

export default ConnectedService
